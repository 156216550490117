export const appHead = {"meta":[{"charset":"utf-8"},{"content":"IE=edge","http-equiv":"X-UA-Compatible"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, viewport-fit=cover"},{"name":"description","content":""}],"link":[{"href":"/favicon.ico","rel":"icon"}],"style":[],"script":[],"noscript":[],"title":"Robogo"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"