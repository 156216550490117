import plugin_vue3_P2C2VpcBeD from "/workspace/node_modules/.pnpm/@pinia+nuxt@0.4.7/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/workspace/business/.nuxt/components.plugin.mjs";
import unhead_hijdvkG1Mr from "/workspace/node_modules/.pnpm/nuxt@3.3.2_sass@1.60.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import vueuse_head_polyfill_kNvnZVrNUq from "/workspace/node_modules/.pnpm/nuxt@3.3.2_sass@1.60.0/node_modules/nuxt/dist/head/runtime/plugins/vueuse-head-polyfill.js";
import router_nYJMD96OxW from "/workspace/node_modules/.pnpm/nuxt@3.3.2_sass@1.60.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_9LMvN8PeKI from "/workspace/node_modules/.pnpm/nuxt@3.3.2_sass@1.60.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_2w0Y4KPpbV from "/workspace/node_modules/.pnpm/@nuxtjs+color-mode@3.2.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import composition_FXjdlAqBkA from "/workspace/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_qercm0Exgv from "/workspace/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_zfdqqOE0OF from "/workspace/node_modules/.pnpm/nuxt@3.3.2_sass@1.60.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_03UruKtxRH from "/workspace/node_modules/.pnpm/nuxt@3.3.2_sass@1.60.0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_W7wbHsX7yG from "/workspace/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.1.1_2fa6k6uov4i2t7fhwaa7f3r2li/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import naive_ui_cjnU5TlW5h from "/workspace/business/plugins/naive-ui.ts";
export default [
  plugin_vue3_P2C2VpcBeD,
  components_plugin_KR1HBZs4kY,
  unhead_hijdvkG1Mr,
  vueuse_head_polyfill_kNvnZVrNUq,
  router_nYJMD96OxW,
  prefetch_client_9LMvN8PeKI,
  plugin_client_2w0Y4KPpbV,
  composition_FXjdlAqBkA,
  i18n_qercm0Exgv,
  chunk_reload_client_zfdqqOE0OF,
  payload_client_03UruKtxRH,
  plugin_W7wbHsX7yG,
  naive_ui_cjnU5TlW5h
]