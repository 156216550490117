import { default as checkoutGSaOEOL3iTMeta } from "/workspace/business/pages/checkout.vue?macro=true";
import { default as indexDxgad9roKhMeta } from "/workspace/business/pages/index.vue?macro=true";
import { default as login9P6j0ETJNLMeta } from "/workspace/business/pages/passport/login.vue?macro=true";
import { default as passport1q7gJ4pc5uMeta } from "/workspace/business/pages/passport.vue?macro=true";
import { default as processes_45tablez8Cd0NxKkkMeta } from "/workspace/business/pages/processes-table.vue?macro=true";
import { default as processesFCurHatksrMeta } from "/workspace/business/pages/processes.vue?macro=true";
import { default as profilekCujaGoGUhMeta } from "/workspace/business/pages/profile.vue?macro=true";
import { default as detailsZQtFSHx6AwMeta } from "/workspace/business/pages/projects/details.vue?macro=true";
import { default as indexg2Puff4BghMeta } from "/workspace/business/pages/projects/index.vue?macro=true";
import { default as projectsLH4HDQ4ICXMeta } from "/workspace/business/pages/projects.vue?macro=true";
import { default as proxy8JVfGsTcfhMeta } from "/workspace/business/pages/proxy.vue?macro=true";
import { default as detailsq6iiQF8GlRMeta } from "/workspace/business/pages/resource/details.vue?macro=true";
import { default as indexjKyfHWNvuJMeta } from "/workspace/business/pages/resource/index.vue?macro=true";
import { default as tableT74Mh9jYvZMeta } from "/workspace/business/pages/resource/table.vue?macro=true";
import { default as resourceca0qTfvKhmMeta } from "/workspace/business/pages/resource.vue?macro=true";
import { default as settingsQCKLfWKBWLMeta } from "/workspace/business/pages/settings.vue?macro=true";
export default [
  {
    name: checkoutGSaOEOL3iTMeta?.name ?? "checkout___en",
    path: checkoutGSaOEOL3iTMeta?.path ?? "/checkout",
    meta: checkoutGSaOEOL3iTMeta || {},
    alias: checkoutGSaOEOL3iTMeta?.alias || [],
    redirect: checkoutGSaOEOL3iTMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutGSaOEOL3iTMeta?.name ?? "checkout___zh",
    path: checkoutGSaOEOL3iTMeta?.path ?? "/zh/checkout",
    meta: checkoutGSaOEOL3iTMeta || {},
    alias: checkoutGSaOEOL3iTMeta?.alias || [],
    redirect: checkoutGSaOEOL3iTMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: indexDxgad9roKhMeta?.name ?? "index___en",
    path: indexDxgad9roKhMeta?.path ?? "/",
    meta: indexDxgad9roKhMeta || {},
    alias: indexDxgad9roKhMeta?.alias || [],
    redirect: indexDxgad9roKhMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexDxgad9roKhMeta?.name ?? "index___zh",
    path: indexDxgad9roKhMeta?.path ?? "/zh",
    meta: indexDxgad9roKhMeta || {},
    alias: indexDxgad9roKhMeta?.alias || [],
    redirect: indexDxgad9roKhMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/index.vue").then(m => m.default || m)
  },
  {
    name: passport1q7gJ4pc5uMeta?.name ?? "passport___en",
    path: passport1q7gJ4pc5uMeta?.path ?? "/passport",
    children: [
  {
    name: login9P6j0ETJNLMeta?.name ?? "passport-login___en",
    path: login9P6j0ETJNLMeta?.path ?? "login",
    meta: login9P6j0ETJNLMeta || {},
    alias: login9P6j0ETJNLMeta?.alias || [],
    redirect: login9P6j0ETJNLMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/passport/login.vue").then(m => m.default || m)
  }
],
    meta: passport1q7gJ4pc5uMeta || {},
    alias: passport1q7gJ4pc5uMeta?.alias || [],
    redirect: passport1q7gJ4pc5uMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/passport.vue").then(m => m.default || m)
  },
  {
    name: passport1q7gJ4pc5uMeta?.name ?? "passport___zh",
    path: passport1q7gJ4pc5uMeta?.path ?? "/zh/passport",
    children: [
  {
    name: login9P6j0ETJNLMeta?.name ?? "passport-login___zh",
    path: login9P6j0ETJNLMeta?.path ?? "login",
    meta: login9P6j0ETJNLMeta || {},
    alias: login9P6j0ETJNLMeta?.alias || [],
    redirect: login9P6j0ETJNLMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/passport/login.vue").then(m => m.default || m)
  }
],
    meta: passport1q7gJ4pc5uMeta || {},
    alias: passport1q7gJ4pc5uMeta?.alias || [],
    redirect: passport1q7gJ4pc5uMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/passport.vue").then(m => m.default || m)
  },
  {
    name: processes_45tablez8Cd0NxKkkMeta?.name ?? "processes-table___en",
    path: processes_45tablez8Cd0NxKkkMeta?.path ?? "/processes-table",
    meta: processes_45tablez8Cd0NxKkkMeta || {},
    alias: processes_45tablez8Cd0NxKkkMeta?.alias || [],
    redirect: processes_45tablez8Cd0NxKkkMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/processes-table.vue").then(m => m.default || m)
  },
  {
    name: processes_45tablez8Cd0NxKkkMeta?.name ?? "processes-table___zh",
    path: processes_45tablez8Cd0NxKkkMeta?.path ?? "/zh/processes-table",
    meta: processes_45tablez8Cd0NxKkkMeta || {},
    alias: processes_45tablez8Cd0NxKkkMeta?.alias || [],
    redirect: processes_45tablez8Cd0NxKkkMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/processes-table.vue").then(m => m.default || m)
  },
  {
    name: processesFCurHatksrMeta?.name ?? "processes___en",
    path: processesFCurHatksrMeta?.path ?? "/processes",
    meta: processesFCurHatksrMeta || {},
    alias: processesFCurHatksrMeta?.alias || [],
    redirect: processesFCurHatksrMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/processes.vue").then(m => m.default || m)
  },
  {
    name: processesFCurHatksrMeta?.name ?? "processes___zh",
    path: processesFCurHatksrMeta?.path ?? "/zh/processes",
    meta: processesFCurHatksrMeta || {},
    alias: processesFCurHatksrMeta?.alias || [],
    redirect: processesFCurHatksrMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/processes.vue").then(m => m.default || m)
  },
  {
    name: profilekCujaGoGUhMeta?.name ?? "profile___en",
    path: profilekCujaGoGUhMeta?.path ?? "/profile",
    meta: profilekCujaGoGUhMeta || {},
    alias: profilekCujaGoGUhMeta?.alias || [],
    redirect: profilekCujaGoGUhMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profilekCujaGoGUhMeta?.name ?? "profile___zh",
    path: profilekCujaGoGUhMeta?.path ?? "/zh/profile",
    meta: profilekCujaGoGUhMeta || {},
    alias: profilekCujaGoGUhMeta?.alias || [],
    redirect: profilekCujaGoGUhMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/profile.vue").then(m => m.default || m)
  },
  {
    path: projectsLH4HDQ4ICXMeta?.path ?? "/projects",
    children: [
  {
    name: detailsZQtFSHx6AwMeta?.name ?? "projects-details___en",
    path: detailsZQtFSHx6AwMeta?.path ?? "details",
    meta: detailsZQtFSHx6AwMeta || {},
    alias: detailsZQtFSHx6AwMeta?.alias || [],
    redirect: detailsZQtFSHx6AwMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/projects/details.vue").then(m => m.default || m)
  },
  {
    name: indexg2Puff4BghMeta?.name ?? "projects___en",
    path: indexg2Puff4BghMeta?.path ?? "",
    meta: indexg2Puff4BghMeta || {},
    alias: indexg2Puff4BghMeta?.alias || [],
    redirect: indexg2Puff4BghMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/projects/index.vue").then(m => m.default || m)
  }
],
    name: projectsLH4HDQ4ICXMeta?.name ?? undefined,
    meta: projectsLH4HDQ4ICXMeta || {},
    alias: projectsLH4HDQ4ICXMeta?.alias || [],
    redirect: projectsLH4HDQ4ICXMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/projects.vue").then(m => m.default || m)
  },
  {
    path: projectsLH4HDQ4ICXMeta?.path ?? "/zh/projects",
    children: [
  {
    name: detailsZQtFSHx6AwMeta?.name ?? "projects-details___zh",
    path: detailsZQtFSHx6AwMeta?.path ?? "details",
    meta: detailsZQtFSHx6AwMeta || {},
    alias: detailsZQtFSHx6AwMeta?.alias || [],
    redirect: detailsZQtFSHx6AwMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/projects/details.vue").then(m => m.default || m)
  },
  {
    name: indexg2Puff4BghMeta?.name ?? "projects___zh",
    path: indexg2Puff4BghMeta?.path ?? "",
    meta: indexg2Puff4BghMeta || {},
    alias: indexg2Puff4BghMeta?.alias || [],
    redirect: indexg2Puff4BghMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/projects/index.vue").then(m => m.default || m)
  }
],
    name: projectsLH4HDQ4ICXMeta?.name ?? undefined,
    meta: projectsLH4HDQ4ICXMeta || {},
    alias: projectsLH4HDQ4ICXMeta?.alias || [],
    redirect: projectsLH4HDQ4ICXMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/projects.vue").then(m => m.default || m)
  },
  {
    name: proxy8JVfGsTcfhMeta?.name ?? "proxy___en",
    path: proxy8JVfGsTcfhMeta?.path ?? "/proxy",
    meta: proxy8JVfGsTcfhMeta || {},
    alias: proxy8JVfGsTcfhMeta?.alias || [],
    redirect: proxy8JVfGsTcfhMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/proxy.vue").then(m => m.default || m)
  },
  {
    name: proxy8JVfGsTcfhMeta?.name ?? "proxy___zh",
    path: proxy8JVfGsTcfhMeta?.path ?? "/zh/proxy",
    meta: proxy8JVfGsTcfhMeta || {},
    alias: proxy8JVfGsTcfhMeta?.alias || [],
    redirect: proxy8JVfGsTcfhMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/proxy.vue").then(m => m.default || m)
  },
  {
    path: resourceca0qTfvKhmMeta?.path ?? "/resource",
    children: [
  {
    name: detailsq6iiQF8GlRMeta?.name ?? "resource-details___en",
    path: detailsq6iiQF8GlRMeta?.path ?? "details",
    meta: detailsq6iiQF8GlRMeta || {},
    alias: detailsq6iiQF8GlRMeta?.alias || [],
    redirect: detailsq6iiQF8GlRMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/resource/details.vue").then(m => m.default || m)
  },
  {
    name: indexjKyfHWNvuJMeta?.name ?? "resource___en",
    path: indexjKyfHWNvuJMeta?.path ?? "",
    meta: indexjKyfHWNvuJMeta || {},
    alias: indexjKyfHWNvuJMeta?.alias || [],
    redirect: indexjKyfHWNvuJMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/resource/index.vue").then(m => m.default || m)
  },
  {
    name: tableT74Mh9jYvZMeta?.name ?? "resource-table___en",
    path: tableT74Mh9jYvZMeta?.path ?? "table",
    meta: tableT74Mh9jYvZMeta || {},
    alias: tableT74Mh9jYvZMeta?.alias || [],
    redirect: tableT74Mh9jYvZMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/resource/table.vue").then(m => m.default || m)
  }
],
    name: resourceca0qTfvKhmMeta?.name ?? undefined,
    meta: resourceca0qTfvKhmMeta || {},
    alias: resourceca0qTfvKhmMeta?.alias || [],
    redirect: resourceca0qTfvKhmMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/resource.vue").then(m => m.default || m)
  },
  {
    path: resourceca0qTfvKhmMeta?.path ?? "/zh/resource",
    children: [
  {
    name: detailsq6iiQF8GlRMeta?.name ?? "resource-details___zh",
    path: detailsq6iiQF8GlRMeta?.path ?? "details",
    meta: detailsq6iiQF8GlRMeta || {},
    alias: detailsq6iiQF8GlRMeta?.alias || [],
    redirect: detailsq6iiQF8GlRMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/resource/details.vue").then(m => m.default || m)
  },
  {
    name: indexjKyfHWNvuJMeta?.name ?? "resource___zh",
    path: indexjKyfHWNvuJMeta?.path ?? "",
    meta: indexjKyfHWNvuJMeta || {},
    alias: indexjKyfHWNvuJMeta?.alias || [],
    redirect: indexjKyfHWNvuJMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/resource/index.vue").then(m => m.default || m)
  },
  {
    name: tableT74Mh9jYvZMeta?.name ?? "resource-table___zh",
    path: tableT74Mh9jYvZMeta?.path ?? "table",
    meta: tableT74Mh9jYvZMeta || {},
    alias: tableT74Mh9jYvZMeta?.alias || [],
    redirect: tableT74Mh9jYvZMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/resource/table.vue").then(m => m.default || m)
  }
],
    name: resourceca0qTfvKhmMeta?.name ?? undefined,
    meta: resourceca0qTfvKhmMeta || {},
    alias: resourceca0qTfvKhmMeta?.alias || [],
    redirect: resourceca0qTfvKhmMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/resource.vue").then(m => m.default || m)
  },
  {
    name: settingsQCKLfWKBWLMeta?.name ?? "settings___en",
    path: settingsQCKLfWKBWLMeta?.path ?? "/settings",
    meta: settingsQCKLfWKBWLMeta || {},
    alias: settingsQCKLfWKBWLMeta?.alias || [],
    redirect: settingsQCKLfWKBWLMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: settingsQCKLfWKBWLMeta?.name ?? "settings___zh",
    path: settingsQCKLfWKBWLMeta?.path ?? "/zh/settings",
    meta: settingsQCKLfWKBWLMeta || {},
    alias: settingsQCKLfWKBWLMeta?.alias || [],
    redirect: settingsQCKLfWKBWLMeta?.redirect || undefined,
    component: () => import("/workspace/business/pages/settings.vue").then(m => m.default || m)
  }
]