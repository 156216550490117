import { setup } from '@css-render/vue3-ssr';
import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  if (process.server) {
    const { collect } = setup(nuxtApp.vueApp);
    const originalRenderMeta = nuxtApp.ssrContext?.renderMeta;
    nuxtApp.ssrContext = nuxtApp.ssrContext || undefined;
    if (!nuxtApp.ssrContext) {
      return;
    }
    nuxtApp.ssrContext.renderMeta = () => {
      if (!originalRenderMeta) {
        return {
          headTags: collect(),
        };
      }
      const originalMeta = originalRenderMeta();
      if ('then' in originalMeta) {
        return originalMeta.then((resolvedOriginalMeta) => {
          return {
            ...resolvedOriginalMeta,
            headTags: resolvedOriginalMeta['headTags'] + collect(),
          };
        });
      } else {
        return {
          ...originalMeta,
          headTags: originalMeta['headTags'] + collect(),
        };
      }
    };
  }

  //https://github.com/huntersofbook/huntersofbook/issues/59
  //修复naive-ui的button组件和tailwindcss样式冲突
  nuxtApp.hook('app:beforeMount', () => {
    if (process.server) return;

    const naive = document.createElement('style');
    naive.textContent = `
    button:is(.n-button), 
    [type='button']:is(.n-button), 
    [type='reset']:is(.n-button), 
    [type='submit']:is(.n-button) {
        background-color: var(--n-color)
    }`;
    document.head.appendChild(naive);
  });
});
