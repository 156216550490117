<template>
  <!-- <NConfigProvider :theme="darkTheme"> -->
  <NConfigProvider :theme-overrides="themeOverrides">
    <NGlobalStyle />
    <NEl tag="div">
      <NMessageProvider>
        <NNotificationProvider>
          <NuxtLayout></NuxtLayout>
        </NNotificationProvider>
      </NMessageProvider>
    </NEl>
  </NConfigProvider>
</template>

<script setup lang='ts'>
import { darkTheme, NEl } from 'naive-ui';

const themeOverrides = {
  common: {
    // primaryColor: '#436ff6',
  }
}

useHead({
  meta: [
    {
      name: 'naive-ui-style',
    }
  ]
});

</script>

